import React, { useState } from 'react';
import { useAuthContext } from "../hooks/useAuthContext";
import { useGetAllApartmentCategories, useUpdateApartmentCategory, useDeleteApartmentCategory } from '../hooks/apartmentCategory';
import { Button, Drawer, Form, Modal, Typography, Card, Spin, Alert, Pagination, Row, notification } from 'antd';
import { EditOutlined, DeleteOutlined, AppstoreOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import ApartmentCategoryForm from '../components/ApartmentCategoryForm';

const { Title, Paragraph } = Typography;

const ApartmentCategoriesPage = () => {
  const { user } = useAuthContext();
  const { apartmentCategories, isLoading, isError, error } = useGetAllApartmentCategories();
  const { updateApartmentCategory } = useUpdateApartmentCategory();
  const { deleteApartmentCategory } = useDeleteApartmentCategory();
  const isAdmin = user?.role === 'admin';
  const [editingCategory, setEditingCategory] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;

  const handleUpdate = async (values) => {
    try {
      const updatedValues = { ...editingCategory, ...values };
      await updateApartmentCategory(updatedValues);
      setEditingCategory(null);
      form.resetFields();
      setIsDrawerOpen(false); // Zatvoriti drawer nakon uspešnog ažuriranja
      notification.success({
        message: 'Uspešno!',
        description: 'Uspešno ste ažurirali kategoriju apartmana.',
      });
    } catch (error) {
      notification.error({
        message: 'Ažuriranje neuspelo!',
        description: 'Postoji problem prilikom ažuriranja kategorije apartmana.',
      });
    }
  };

  const handleEditClick = (category) => {
    setEditingCategory(category);
    form.setFieldsValue({
      name: category.name,
      email: category.email,
      maintenanceCosts: category.maintenanceCosts,
      desc: category.desc,
      phoneNumber: category.phoneNumber, // Dodaj i phoneNumber
    });
    setIsDrawerOpen(true); // Otvoriti drawer prilikom editovanja
  };

  const handleDeleteClick = (categoryId) => {
    Modal.confirm({
      title: 'Da li ste sigurni da želite da izbrišete kategoriju apartmana?',
      content: 'Ova akcija se ne može vratiti.',
      okText: 'Da, izbriši.',
      okType: 'danger',
      cancelText: 'Odustani',
      onOk: async () => {
        try {
          await deleteApartmentCategory(categoryId);
          notification.success({
            message: 'Uspešno!',
            description: 'Uspešno ste izbrisali kategoriju apartmana.',
          });
        } catch (error) {
          notification.error({
            message: 'Neuspešno brisanje!',
            description: 'Postoji greška prilikom brisanja kategorije apartmana.',
          });
        }
      },
    });
  };

  if (isError) return <Alert message={`Error: ${error.message}`} type="error" showIcon />;

  const paginatedCategories = apartmentCategories?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  const totalCategories = apartmentCategories?.length;

  return (
    <div className="container container-wrap mx-auto">
      <Title level={3} className="flex items-center justify-between" style={{ color: "#3730a3", textAlign: "center", margin: '5px 0 15px 0' }}>
        <div></div>
        <div> <AppstoreOutlined style={{ fontSize: '25px', marginRight: '5px' }} /> Kategorije apartmana </div>
       {isAdmin &&   <Button className="create-reservation-btn"type="primary" onClick={() => { setIsDrawerOpen(true); setEditingCategory(null); }}><AppstoreAddOutlined  style={{ fontSize: '20px' }}/> <span>Kreiraj apartman</span></Button>}
      </Title>
      {isLoading ? (
        <Spin size="small" />
      ) : (
        <Row gutter={16}>
          {paginatedCategories.map((category) => (
            <Card
              key={category.id}
              style={{
                width: 300,
                borderRadius: "8px",
                boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
                margin: '20px',
              }}
            >
              <Card.Meta
                title={
                  <div className="text-lg font-semibold text-gray-900 mx-auto">
                    <AppstoreOutlined /> {category.name}
                    <EditOutlined className='edit-icon'    onClick={() => handleEditClick(category)} />
                    <DeleteOutlined className='delete-icon'    onClick={() => handleDeleteClick(category.id)}/>
                  </div>
                }
                description={
                  <div className="mt-2">
                    <Paragraph className="text-gray-900">Email: {category.email}</Paragraph>
                    <Paragraph className="text-gray-900">
                      Broj telefona: {category.phoneNumber}
                    </Paragraph>
                    <Paragraph className="text-gray-900">Opis: {category.desc}</Paragraph>
                  </div>
                }
              />
            </Card>
          ))}
                <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalCategories}
        onChange={(page) => setCurrentPage(page)}
        className="w-full pagination-content"
      />
        </Row>
      )}



      <Drawer
        width={640}
        placement="right"
        closable={true}
        onClose={() => setIsDrawerOpen(false)}
        open={isDrawerOpen}
        title={editingCategory ? `Edituj Kategoriju - ${editingCategory.name}` : "Kreiraj Kategoriju"}
      >
        <ApartmentCategoryForm
          category={editingCategory} // Prosledi izabranu kategoriju ako postoji
          onClose={() => setIsDrawerOpen(false)} // Zatvori nakon kreiranja ili editovanja
          onUpdate={handleUpdate} // Prosledi funkciju za ažuriranje
          form={form} // Prosledi formu da bude povezana
        />
      </Drawer>
    </div>
  );
};

export default ApartmentCategoriesPage;
