import React, { useState } from "react";
import { useGetAllReservations,useUpdateReservation,  useDeleteReservation ,useCloseReservation } from "../hooks/reservation";
import ReservationForm from "../components/ReservationForm";
import { Link } from "react-router-dom";
import { Button, Card, Modal, Pagination, Typography, Tabs, notification, Drawer } from "antd";
import { EditOutlined, DeleteOutlined, ScheduleOutlined, TeamOutlined, UserOutlined, CheckSquareFilled, ShopOutlined , ScheduleFilled } from "@ant-design/icons";
import dayjs from 'dayjs';
const { Title, Paragraph } = Typography;

const ReservationsPage = () => {
  const { reservations = [] } = useGetAllReservations();
  const { updateReservation } = useUpdateReservation();
  const { deleteReservation } = useDeleteReservation();
  const { closeReservation } = useCloseReservation();

  const [activeTab, setActiveTab] = useState("Active");
  const [isDrawerVisible, setisDrawerVisible] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCloseModalVisible, setIsCloseModalVisible] = useState(false);
  const [formKey, setFormKey] = useState(Date.now());
  const [current, setCurrent] = useState(1);
  const pageSize = 10;

  const showModal = (reservation) => {
    setSelectedReservation(reservation);
    setisDrawerVisible(true);
  };

  const showDeleteModal = (reservation) => {
    setSelectedReservation(reservation);
    setIsDeleteModalVisible(true);
  };

  const showCloseModal = (reservation) => {
    setSelectedReservation(reservation);
    setIsCloseModalVisible(true);
  };

  const handleCancel = () => {
    setisDrawerVisible(false);
    setSelectedReservation(null);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedReservation(null);
  };

  const handleCloseCancel = () => {
    setIsCloseModalVisible(false);
    setSelectedReservation(null);
  };

  const formatReservationData = (values) => {
    return {
      ...values,
    };
  };

  const handleUpdateReservation = async (values) => {
    const formattedValues = formatReservationData({
      ...values,
      id: selectedReservation.id,
    });
    try {
      await updateReservation(formattedValues);
      setFormKey(Date.now());
      setisDrawerVisible(false);
      setSelectedReservation(null);
      notification.success({
        message: 'Uspešno!',
        description: 'Uspešno ste editovali rezervaciju!.',
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Editovanje neuspelo!',
        description: 'Postoji greška prilikom editovanja rezervacije.',
      });
    }
  };

  const handleDeleteReservation = async () => {
    try {
      await deleteReservation(selectedReservation.id);
      setIsDeleteModalVisible(false);
      setSelectedReservation(null);
      notification.success({
        message: 'Uspešno!',
        description: 'Uspešno ste obrisali rezervaciju!.',
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Brisanje neuspelo!',
        description: 'Postoji greška prilikom brisanja rezervacije.',
      });
    }
  };

  const handleCloseReservation = async () => {
    try {
      await closeReservation(selectedReservation.id);
      setIsCloseModalVisible(false);
      setSelectedReservation(null);
      notification.success({
        message: 'Uspešno!',
        description: 'Uspešno ste zatvorili rezervaciju!.',
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Zatvaranje neuspelo!',
        description: 'Postoji greška prilikom zatvaranja rezervacije.',
      });
    }
  };

  // Filter reservations based on the active tab
  const renderReservationsByCategory = (status) => {
    return reservations.filter(
      (reservation) => (status === "Active" ? !reservation.closed : reservation.closed)
    );
  };

  // Handle pagination changes
  const handlePageChange = (page) => {
    setCurrent(page);
  };

  // Paginate the reservations
  const paginatedReservations = renderReservationsByCategory(activeTab)?.slice(
    (current - 1) * pageSize,
    current * pageSize
  );
  const apartment = {
    id: selectedReservation?.apartmentId
  }

  // Tabs items configuration
  const items = [
    {
      key: "Active",
      label: "Aktivne",
      children: (
        <div className="reservations-content flex flex-wrap gap-4 mt-5 mb-10">
          {paginatedReservations.length > 0 ? (
            paginatedReservations.map((reservation) => (
              <Card
                key={reservation.id}
                style={{
                  width: 300,
                  borderRadius: "8px",
                  boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
                }}
                actions={[
                  <Button
                    type="link"
                    icon={<EditOutlined />}
                    onClick={() => showModal(reservation)} 
                  >
                    Edituj
                  </Button>,
                  <Button
                    danger
                    type="link"
                    icon={<DeleteOutlined />}
                    onClick={() => showDeleteModal(reservation)}
                  >
                    Obriši
                  </Button>,
                ]}
              >
                <Card.Meta
                  title={
                    <div className="flex justify-between items-center">
                    <div className="text-lg text-center"><UserOutlined /> {reservation.guestName} </div>  
                    <Button    
                      className="close-reservation-btn"
                      type="link"
                      onClick={() => showCloseModal(reservation)} 
                      icon={<CheckSquareFilled />}
                      >
                      Zatvori
                    </Button>
                    </div>
                  }
                  description={
                    <> 
                      <Paragraph className="text-indigo-600 text-lg">
                      <ShopOutlined style={{ fontSize: '20px' }} /> {reservation.apartmentName}
                      </Paragraph>
                      <Paragraph className="text-indigo-600 text-lg">
                       {dayjs(reservation.startDate).format('DD MMM YYYY')} - {dayjs(reservation.endDate).format('DD MMM YYYY')}
                      </Paragraph>
                      <Paragraph className="text-gray-900 text-base">
                        <ScheduleOutlined style={{ fontSize: '20px' }} /> {reservation.bookedType}
                      </Paragraph>
                      <Paragraph className="text-gray-900 text-base">
                        <TeamOutlined style={{ fontSize: '20px' }} /> {reservation.guestNumber}
                      </Paragraph>
                      <Paragraph className="text-gray-900 text-base">
                        Phone: {reservation.guestPhone}
                      </Paragraph>
                      <Paragraph className="text-gray-900 text-base">
                        Email: {reservation.guestEmail}
                      </Paragraph>
                      <Paragraph className="font-medium text-gray-900 text-base">
                        Ukupna cena: {reservation.priceSum} din
                      </Paragraph>
                      <Paragraph className="font-medium text-gray-900 text-base">
                        Troskovi: {reservation.costs} din
                      </Paragraph>
                      <Paragraph className="font-medium text-gray-900 text-base">
                        Vlasnik zarada: {reservation.ownerEarnings} din
                      </Paragraph>
                      <Paragraph className="font-medium text-indigo-600 text-lg text-right">
                        Zarada: {reservation.myEarnings} din
                      </Paragraph>
                      </>
                  }
                />
              </Card>
            ))
          ) : (
            <div className="text-center w-full">
              <Paragraph>No reservations in this category.</Paragraph>
            </div>
          )}
        </div>
      ),
    },
    {
      key: "Closed",
      label: "Zatvorene",
      children: (
        <div className="reservations-content flex flex-wrap gap-4 mt-5 mb-10">
          {paginatedReservations.length > 0 ? (
            paginatedReservations.map((reservation) => (
              <Card
                key={reservation.id}
                style={{
                  width: 300,
                  borderRadius: "8px",
                  boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
                }}
                actions={[]}
              >
                <Card.Meta
                  title={
                    <Link
                      to={`/reservation/${reservation.id}`}
                      className="reservation-title text-lg font-semibold text-gray-900"
                    >
                      <UserOutlined /> {reservation.guestName}
                    </Link>
                  }
                  description={
                    <Link
                      to={`/reservation/${reservation.id}`}
                      className="text-lg font-semibold text-gray-900"
                    >
                     <Paragraph className="text-indigo-600 text-lg">
                       {dayjs(reservation.startDate).format('DD MMM YYYY')} - {dayjs(reservation.endDate).format('DD MMM YYYY')}
                      </Paragraph>
                      <Paragraph className="text-gray-900 text-base">
                        <ScheduleOutlined style={{ fontSize: '20px' }} /> {reservation.bookedType}
                      </Paragraph>
                      <Paragraph className="text-gray-900 text-base">
                        <TeamOutlined style={{ fontSize: '20px' }} /> {reservation.guestNumber}
                      </Paragraph>
                      <Paragraph className="text-gray-900 text-base">
                        Phone: {reservation.guestPhone}
                      </Paragraph>
                      <Paragraph className="text-gray-900 text-base">
                        Email: {reservation.guestEmail}
                      </Paragraph>
                      <Paragraph className="font-medium text-gray-900 text-base">
                        Ukupna cena: {reservation.priceSum} din
                      </Paragraph>
                      <Paragraph className="font-medium text-gray-900 text-base">
                        Troskovi: {reservation.costs} din
                      </Paragraph>
                      <Paragraph className="font-medium text-gray-900 text-base">
                        Vlasnik zarada: {reservation.ownerEarnings} din
                      </Paragraph>
                      <Paragraph className="font-medium text-indigo-600 text-lg text-right">
                        Zarada: {reservation.myEarnings} din
                      </Paragraph>
                    </Link>
                  }
                />
              </Card>
            ))
          ) : (
            <div className="text-center w-full">
              <Paragraph>No reservations in this category.</Paragraph>
            </div>
          )}
        </div>
      ),
    }
  ];

  return (
    <div className="mx-auto">
      <Title level={3} className="flex items-center justify-center" style={{ color: "#3730a3", textAlign: "center", margin: '5px 0 15px 0' }}>
      <ScheduleFilled  style={{ fontSize: '25px', marginRight: '8px' }} />  Rezervacije
      </Title>

      <Tabs
        defaultActiveKey="Active"
        type="card"
        items={items}
        onChange={(key) => {
          setActiveTab(key);
          setCurrent(1); 
        }}
      />

      <Pagination
        current={current}
        pageSize={pageSize}
        total={renderReservationsByCategory(activeTab)?.length}
        onChange={handlePageChange}
        className="pagination-content"
      />
        <Drawer width={640} placement="right" closable={true}  onClose={handleCancel} open={isDrawerVisible}    
        title={`Izmeni rezervaciju za ${selectedReservation?.guestName}`}> 
            <ReservationForm
              onFinish={handleUpdateReservation}
              apartment={apartment}
              formKey={formKey}
              initialValues={selectedReservation}
            />
          </Drawer>

      <Modal
        style={{ top: 10 }}
        open={isDeleteModalVisible}
        title={`Izbriši rezervaciju`}
        onCancel={handleDeleteCancel}
        onOk={handleDeleteReservation}
        okText="Delete"
        cancelText="Cancel"
        width={400}
      >
        <Paragraph>Da li ste sigurni da želite da izbrišete rezervaciju za gosta {selectedReservation?.guestName} od  {dayjs(selectedReservation?.startDate).format('DD MMM YYYY')} - {dayjs(selectedReservation?.endDate).format('DD MMM YYYY')}</Paragraph>
      </Modal>

      <Modal
        style={{ top: 10 }}
        open={isCloseModalVisible}
        title={`Zatvori rezervaciju`}
        onCancel={handleCloseCancel}
        onOk={handleCloseReservation}
        okText="Close"
        cancelText="Cancel"
        width={400}
      >
         <Paragraph>Da li ste sigurni da želite da zatvorite rezervaciju za gosta {selectedReservation?.guestName} od {dayjs(selectedReservation?.startDate).format('DD MMM YYYY')} - {dayjs(selectedReservation?.endDate).format('DD MMM YYYY')}?</Paragraph>
      </Modal>
    </div>
  );
};

export default ReservationsPage;
