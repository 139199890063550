import React from "react";
import { Form, Input, DatePicker, Button, message } from "antd";
import { useCreateGuest } from "../hooks/guest";
import dayjs from "dayjs";

const GuestForm = ({onGuestAdded}) => {
  const { createGuest, error, isSuccess } = useCreateGuest();
  const [form] = Form.useForm();

  const handleFinish = async (values) => {
    try {
      // Formatiramo datum pomoću dayjs pre slanja
      const formattedValues = {
        ...values,
        birthOfDate: values.birthOfDate
          ? dayjs(values.birthOfDate).format("YYYY-MM-DD")
          : null,
      };
      if (onGuestAdded) {
        onGuestAdded();
      }
      await createGuest(formattedValues);
      message.success("Gost je uspešno kreiran!");
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error("Došlo je do greške prilikom kreiranja gosta.");
    }
  };

  return (

      <div>
        {isSuccess && (
          <p className="text-green-500 text-center mb-4">{isSuccess}</p>
        )}
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFinish}
          className="space-y-6"
        >
          <Form.Item
            label="Ime"
            name="name"
            rules={[{ required: true, message: 'Molimo unesite ime!' }]}
          >
            <Input placeholder="Ime" />
          </Form.Item>
          <Form.Item
            label="Datum rođenja"
            name="birthOfDate"
          >
            <DatePicker 
             inputReadOnly
             />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ message: 'Molimo unesite email!' }]}
          >
            <Input type="text" placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Broj telefona"
            name="phoneNumber"
            rules={[{ required: true, message: 'Molimo unesite broj telefona!' }]}
          >
            <Input type="number" placeholder="Broj telefona" />
          </Form.Item>
          <Form.Item
            label="JMBG"
            name="jmbg"
            rules={[{ message: 'Molimo unesite JMBG!' }]}
          >
            <Input placeholder="JMBG" />
          </Form.Item>
          <Form.Item
            label="Opis"
            name="desc"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="w-full flex justify-center"
            >
              Kreiraj gosta
            </Button>
          </Form.Item>
          {error && <div className="text-sm text-red-600">{error}</div>}
        </Form>
      </div>
 
  );
};

export default GuestForm;
