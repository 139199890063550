import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useClient } from "../utils/client";
import { useAuthContext } from "./useAuthContext";

const APARTMENTS_KEY = "apartments";

const useCreateApartment = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  const { mutateAsync: createApartment, ...rest } = useMutation({
    mutationFn: (apartment) => {
      return client("apartment/createApartment", {
        method: "POST",
        body: apartment,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(APARTMENTS_KEY);
    },
  });

  return { ...rest, createApartment };
};

const useGetApartment = (apartmentId) => {
  const client = useClient();

  const { data: apartment, ...rest } = useQuery({
    queryKey: ["apartment", apartmentId],
    queryFn: () => client(`apartment/${apartmentId}`),
    enabled: !!apartmentId, 
  });

  return { ...rest, apartment };
};


const useGetAllApartments = () => {
  const client = useClient();
  const { data: apartments, ...rest } = useQuery({
    queryKey: [APARTMENTS_KEY],
    queryFn: () => client("apartment/allApartments"),
  });

  return { ...rest, apartments };
};


const useUpdateApartment = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { mutateAsync: updateApartment, ...rest } = useMutation({
    mutationFn: (updatedApartment) =>
      client(`apartment/updateApartment/${updatedApartment.id}`, {
        method: "PUT",
        body: updatedApartment,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(APARTMENTS_KEY);
    },
  });
  return { ...rest, updateApartment };
};

const useDeleteApartment = () => {
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const client = useClient();
  const { mutateAsync: deleteApartment, ...rest } = useMutation({
    mutationFn: (apartmentId) =>
      client(`apartment/deleteApartment/${apartmentId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(APARTMENTS_KEY);
    },
  });

  return { ...rest, deleteApartment };
};

const useAvailableApartments = (startDate, endDate) => {
  const client = useClient();
  const { user } = useAuthContext();
  const { data: availableApartments, ...rest } = useQuery({
    queryKey: ["availableApartments", startDate, endDate],
    queryFn: () => {
      const url = `apartment/availableApartments?startDate=${startDate}&endDate=${endDate}`;
      return client(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
    },
    enabled: !!startDate && !!endDate,
  });

  return { ...rest, availableApartments };
};

export {
  useCreateApartment,
  useGetApartment,
  useGetAllApartments,
  useUpdateApartment,
  useDeleteApartment,
  useAvailableApartments,
};
