import React, { useEffect, useState } from 'react';
import { Form, InputNumber, Button, DatePicker, Radio, Input, Select, Drawer } from 'antd';
import dayjs from 'dayjs';
import GuestForm from './GuestForm';
import { useGetAllGuests } from '../hooks/guest';
import { useGetReservationsByApartmentId } from '../hooks/reservation';

const { RangePicker } = DatePicker;
const { Option } = Select;

const ReservationForm = ({ onFinish, initialValues, apartment, formKey }) => {
  const { guests, isLoading: guestsLoading, isError: guestsError, refetch } = useGetAllGuests();
  const { reservations } = useGetReservationsByApartmentId(apartment.id);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [disabledDates, setDisabledDates] = useState([]);
  const [currentReservation, setCurrentReservation] = useState(null);

  useEffect(() => {
    if (reservations) {
      const filteredReservations = reservations.filter(reservation => {
        if (currentReservation) {
          return reservation.id !== currentReservation.id;
        }
        return true;
      });

      const dates = filteredReservations.flatMap(reservation => {
        if (reservation.startDate && reservation.endDate) {
          const start = dayjs(reservation.startDate);
          const end = dayjs(reservation.endDate);
          const datesArray = [];
          for (let d = start; d.isBefore(end, 'day'); d = d.add(1, 'day')) {
            datesArray.push(d.format('YYYY-MM-DD'));
          }
          datesArray.push(end.format('YYYY-MM-DD'));
          return datesArray;
        }
        return [];
      });
      setDisabledDates(dates);
    }
  }, [reservations, currentReservation]);

  useEffect(() => {
    if (initialValues) {
      const { startDate, endDate } = initialValues;
      setCurrentReservation({ ...initialValues });

      // Set initial values for the form
      form.setFieldsValue({
        startDate: startDate ? dayjs(startDate) : null,
        endDate: endDate ? dayjs(endDate) : null,
        dateRange: startDate && endDate ? [dayjs(startDate), dayjs(endDate)] : [],
        guestId: initialValues.guestId || '',
        guestName: initialValues.guestName || '',
        guestPhone: initialValues.guestPhone || '',
        guestEmail: initialValues.guestEmail || '',
        pricePerDay: initialValues.pricePerDay || '',
        costs: initialValues.costs || '',
        guestNumber: initialValues.guestNumber || '',
        bookedType: initialValues.bookedType || 'booking',
        type: initialValues.type || 'night',
      });
    } else {
      form.resetFields(); // Clear the form if no initial values
    }
  }, [form, formKey, initialValues]);

  const isDateDisabled = (current) => {
    if (!current) {
      return false;
    }
    const formattedDate = current.format('YYYY-MM-DD');
    if (currentReservation) {
      const { startDate, endDate } = currentReservation;
      const isWithinCurrentRange = dayjs(startDate).isBefore(current, 'day') && dayjs(endDate).isAfter(current, 'day');
      if (isWithinCurrentRange) {
        return false;
      }
    }
    return disabledDates.includes(formattedDate);
  };

  const handleAddGuest = () => {
    setIsModalVisible(true);
  };

  const handleGuestAdded = () => {
    setIsModalVisible(false);
    refetch(); 
  };

  const handleSelectGuest = (guestId) => {
    const guest = guests.find(g => g.id === guestId);
    if (guest) {
      form.setFieldsValue({
        guestId: guest.id,
        guestName: guest.name,
        guestPhone: guest.phoneNumber,
        guestEmail: guest.email,
      });
    }
  };

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const [startDate, endDate] = dates;
      form.setFieldsValue({
        startDate: startDate ? startDate.format('YYYY-MM-DD') : '',
        endDate: endDate ? endDate.format('YYYY-MM-DD') : '',
      });
    }
  };

  const handleModalClose = () => {
    // Clear the form if we're not editing an existing reservation
    if (!initialValues) {
      form.resetFields();
    }
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="reservation-form-container">
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          className="reservation-form"
        >
       {/* Add New Guest */}
          <div className='create-guest-box'>
            <p>Ukoliko gost vec nije kreiraj, molimo vas kreirajte ga: </p>
            <Button
              type="primary"
              onClick={handleAddGuest}
              style={{ marginTop: 8 }}
            >
              Kreiraj gosta
            </Button>
          </div>

          {/* Select Guest */}
          <Form.Item
            name="guestId"
            label="Gost:"
            rules={[{ required: true, message: 'Molimo vas selektujte gosta!' }]}
          >
            <Select
              showSearch
              placeholder="Selektuj gosta"
              loading={guestsLoading}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: '100%' }}
              onChange={handleSelectGuest}
            >
              {guestsError ? (
                <Option disabled>Error loading guests</Option>
              ) : (
                guests?.map(guest => (
                  <Option key={guest.id} value={guest.id}>
                    {guest.name}
                  </Option>
                ))
              )}
            </Select>
          </Form.Item>

          {/* Hidden Fields for Guest Info */}
          <Form.Item name="guestName" style={{ display: 'none' }}>
            <Input />
          </Form.Item>

          <Form.Item name="guestPhone" style={{ display: 'none' }}>
            <Input />
          </Form.Item>

          <Form.Item name="guestEmail" style={{ display: 'none' }}>
            <Input />
          </Form.Item>

           {/* Date Range */}
        <Form.Item
            name="dateRange"
            label="Datum rezervacije(od - do):"
            rules={[{ required: true, message: 'Molimo vas unesite datum rezervacije!' }]}
          >
            <RangePicker
              inputReadOnly
              style={{ width: '100%' }}
              disabledDate={isDateDisabled}
              onChange={handleDateRangeChange}
            />
          </Form.Item>

          {/* Hidden fields for startDate and endDate */}
          <Form.Item name="startDate" style={{ display: 'none' }}>
            <Input />
          </Form.Item>

          <Form.Item name="endDate" style={{ display: 'none' }}>
            <Input />
          </Form.Item>

          {/* Price Per Day */}
          <Form.Item
            name="pricePerDay"
            label="Cena po noćenju:"
            rules={[{ required: true, message: 'Molimo vas unesite cenu nocenja!' }]}
          >
            <InputNumber min={0} style={{ width: '100%' }}  suffix="din" />
          </Form.Item>

          {/* Costs */}
          <Form.Item
            name="costs"
            label="Troškovi:"
            rules={[{ required: true, message: 'Molimo vas unesite troškove!' }]}
          >
            <InputNumber min={0} style={{ width: '100%' }} suffix="din" />
          </Form.Item>

          {/* Number of Guests */}
          <Form.Item
            name="guestNumber"
            label="Broj gostiju u smeštaju:"
            rules={[{ required: true, message: 'Molimo vas unesite broj gostiju!' }]}
          >
            <InputNumber min={1} style={{ width: '100%' }} />
          </Form.Item>

          {/* Booking Type */}
          <Form.Item
            name="bookedType"
            label="Način rezervacije"
            rules={[{ required: true, message: 'Molimo vas unesite način rezervacije!' }]}
          >
            <Radio.Group>
              <Radio value="booking">Booking</Radio>
              <Radio value="phone">Telefon</Radio>
            </Radio.Group>
          </Form.Item>
        {/* Reservation Type */}
          <Form.Item
          name="type"
            label="Odmor"
            rules={[{ required: true, message: 'Molimo vas unesite tip odmora!' }]}
          >
            <Radio.Group>
              <Radio value="night">Noćni</Radio>
              <Radio value="day">Dnevni</Radio>
            </Radio.Group>
          </Form.Item>


          {/* Submit Button */}
          <Form.Item className='create-reservation-btn-box w-full'>
            <Button type="primary" htmlType="submit" >
             {initialValues?.pricePerDay ? "Ažuriraj!" : "Kreiraj!"}
            </Button>
          </Form.Item>
        </Form>

        {/* Drawer for Adding New Guest */}  
        <Drawer width={640} placement="right" closable={true} onClose={handleModalClose} open={isModalVisible} title="Kreiraj gosta" >
        <GuestForm onGuestAdded={handleGuestAdded} />
        </Drawer>
      </div>
    </>
  );
};

export default ReservationForm;
