import axios from "axios";
import { useAuthContext } from "../hooks/useAuthContext";


export const useClient = () => {
  const { user } = useAuthContext();
  const BASE_URL = process.env.REACT_APP_BASE_URL;
    
  const client = async (
    endpoint,
    { method = "GET", body, withoutAutorization = false } = {}
  ) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };

      if (!withoutAutorization) {
        headers.Authorization = `Bearer ${user.token}`;
      }

      const response = await axios({
        method,
        url: `${BASE_URL}${endpoint}`,
        data: body,
        headers,
      });

      if (response.status >= 400) {
        if (response.status === 401) {
          // TODO: dispatch("LOGOUT_USER")
        }
        throw new Error(response.data.error);
      }
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  };

  return client;
};
