import React, { useState } from "react";
import { useLogin } from "../hooks/login";
import { Form, Input, Button, message } from "antd"; 

const Login = () => {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const { login, isLoading } = useLogin();


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    try {
      await login(formData);
      message.success("Uspešno ste se prijavili!"); // Success message
    } catch (error) {
      console.error(error);
      message.error("Došlo je do greške prilikom prijave."); // Error message
    }
  };

  return (
    <div className="flex items-start justify-center mt-10">
      <div className="welcome-card space-y-8 bg-white p-6 rounded-lg shadow-lg" style={{ width: '400px', height: '400px', boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px", margin: "50px auto", border: '2px solid #473fcf' }}>
        <div>
          <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
            Prijavi se
          </h2>
        </div>
        <Form layout="vertical" onFinish={handleSubmit}>
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Molimo unesite email!" }]}
          >
            <Input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              placeholder="Email adresa"
            />
          </Form.Item>

          <Form.Item
            label="Šifra"
            name="password"
            rules={[{ required: true, message: "Molimo unesite šifru!" }]}
          >
            <Input.Password
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Šifra"
            />
          </Form.Item>

          <Form.Item>
            <Button
            className="mt-5"
              type="primary"
              htmlType="submit"
              block
              loading={isLoading}
            >
              Prijavi se
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default Login;
