import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useClient } from "../utils/client";
import { useAuthContext } from "./useAuthContext";
const APARTMENT_CATEGORY_KEY = "apartmentCategory";

const useCreateApartmentCategory = () => {
  const client = useClient();
  const queryClient = useQueryClient();

  const { mutateAsync: createApartmentCategory, ...rest } = useMutation({
    mutationFn: (apartmentCategory) => {
      return client("apartmentCategory/createApartmentCategory", {
        method: "POST",
        body: apartmentCategory,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(APARTMENT_CATEGORY_KEY);
    },
  });

  return { ...rest, createApartmentCategory };
};

const useGetApartmentCategory = (categoryId) => {
  const client = useClient();

  const { data: apartmentCategory, ...rest } = useQuery({
    queryKey: [APARTMENT_CATEGORY_KEY, categoryId],
    queryFn: () => client(`apartmentCategory/${categoryId}`),
    enabled: !!categoryId, 
  });

  return { ...rest, apartmentCategory };
};

const useGetAllApartmentCategories = () => {
  const client = useClient();
  const { data: apartmentCategories, ...rest } = useQuery({
    queryKey: [APARTMENT_CATEGORY_KEY],
    queryFn: () => client("apartmentCategory/allApartmentCategories"),
  });

  return { ...rest, apartmentCategories };
};

const useUpdateApartmentCategory = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { mutateAsync: updateApartmentCategory, ...rest } = useMutation({
    mutationFn: (updatedCategory) =>
      client(`apartmentCategory/updateApartmentCategory/${updatedCategory.id}`, {
        method: "PUT",
        body: updatedCategory,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(APARTMENT_CATEGORY_KEY);
    },
  });
  return { ...rest, updateApartmentCategory };
};

const useDeleteApartmentCategory = () => {
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const client = useClient();
  const { mutateAsync: deleteApartmentCategory, ...rest } = useMutation({
    mutationFn: (categoryId) =>
      client(`apartmentCategory/deleteApartmentCategory/${categoryId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(APARTMENT_CATEGORY_KEY);
    },
  });

  return { ...rest, deleteApartmentCategory };
};

export {
  useCreateApartmentCategory,
  useGetApartmentCategory,
  useGetAllApartmentCategories,
  useUpdateApartmentCategory,
  useDeleteApartmentCategory,
};
