import React, { useEffect } from "react";
import { Form, Input, Button, Alert, Select, InputNumber } from "antd";
import { useCreateApartment, useUpdateApartment } from "../hooks/apartment";
import { useGetAllApartmentCategories } from "../hooks/apartmentCategory";

const ApartmentForm = ({ apartment, onClose }) => {
  const isEditMode = !!apartment; // Da li je edit mod ili ne
  const { createApartment, error, isSuccess } = useCreateApartment();
  const { updateApartment } = useUpdateApartment();
  const { apartmentCategories, isLoading: isLoadingCategories } = useGetAllApartmentCategories();
  
  const [form] = Form.useForm();

  useEffect(() => {
    if (isEditMode) {
      form.setFieldsValue(apartment); // Popuni formu postojećim podacima u edit modu
    }
    if (onClose) {
      form.resetFields();
    }
  }, [apartment, form, isEditMode,onClose]);

  const onFinish = async (formData) => {
    try {
      if (isEditMode) {
        await updateApartment({ ...formData, id: apartment.id });
      } else {
        await createApartment(formData);
      }

      if (!error) {
        form.resetFields();
        if (onClose) onClose();
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      {isSuccess && (
        <Alert message={isEditMode ? "Stan uspešno izmenjen!" : "Stan uspešno kreiran!"} type="success" showIcon className="mb-4" />
      )}
      {error && (
        <Alert message={error} type="error" showIcon className="mb-4" />
      )}
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        className="space-y-6"
        initialValues={isEditMode ? apartment : {}} // Postavi početne vrednosti
      >
        <Form.Item
          label="Ime:"
          name="name"
          rules={[{ required: true, message: "Molimo unesite ime stana!" }]}
        >
          <Input placeholder="Ime" />
        </Form.Item>

        <Form.Item
          label="Adresa:"
          name="address"
          rules={[{ required: true, message: "Molimo unesite adresu!" }]}
        >
          <Input placeholder="Adresa" />
        </Form.Item>

        <Form.Item
          label="Cena po noćenju:"
          name="pricePerDay"
          rules={[{ required: true, message: "Molimo unesite cenu po noćenju!" }]}
        >
          <InputNumber min={0} style={{ width: '100%' }} suffix="din" />
        </Form.Item>

        <Form.Item
          label="Maksimalan broj gostiju:"
          name="guestNumber"
          rules={[{ required: true, message: "Molimo unesite broj gostiju!" }]}
        >
          <InputNumber type="number" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Tip smeštaja:"
          name="type"
          rules={[{ message: "Molimo unesite tip!" }]}
        >
          <Select
            placeholder="Izaberite tip smeštaja:"
          >
            <Select.Option key={1} value="Soba">Soba</Select.Option>
            <Select.Option key={2} value="Studio">Studio</Select.Option>
            <Select.Option key={3} value="Apartman">Apartman</Select.Option>
            <Select.Option key={4} value="Stan">Stan</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Procenat:"
          name="percentage"
        >
          <InputNumber type="number" style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item
          label="Opis:"
          name="desc"
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Kategorija apartmana:"
          name="apartmentCategoryId"
          rules={[{ required: true, message: "Molimo izaberite Kategoriju Stana!" }]}
        >
          <Select
            placeholder="Izaberite kategoriju kojoj apartman pripada:"
            loading={isLoadingCategories}
          >
            {apartmentCategories?.map((category) => (
              <Select.Option key={category.id} value={category.id}>
                {category.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            {isEditMode ? "Izmeni apartman" : "Kreiraj apartman"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ApartmentForm;
