import React from "react";
import { Link } from "react-router-dom";
import {
   GitlabFilled,UserAddOutlined
} from '@ant-design/icons';
const WelcomeMessage = () => {
  return (
    <div className="flex items-start justify-center mt-10">
      <div className="welcome-card  max-w-2xl w-full bg-white rounded-lg overflow-hidden"style={{ width: '400px', heigh:'400px', boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px", margin: "30px auto" }}>
        <div className="px-6 py-8" style={{ backgroundColor: "#5046e5", color: '#fff' }}>
          <div className="text-center">
            <h2 className="mb-4 text-3xl leading-9 font-extrabold text-white-900">
              Dobrodošli na našu aplikaciju za rezervacije!
            </h2>
            <p className="mb-4 mt-2 text-sm">
              Ovo je intuitivna aplikacija za rezervacije i praćenje gostiju, koja je
              osmišljena kako bi korisnicima pomogla u efikasnom upravljanju smeštajem 
              i rezervacijama. Pojednostavite proces rezervacija i pratite svoje goste 
              na jednostavan način, uz našu korisnički orijentisanu platformu i 
              sveobuhvatne alate za upravljanje gostima.
            </p>
          </div>
          <div className="mt-8 space-y-6">
            <Link
              to="/house"
              className="block w-full py-3 px-6 border border-transparent text-sm font-medium rounded-md homepage-link"
            >
             <GitlabFilled style={{ fontSize: '20px', marginRight: '10px'}} />  Kreiraj kompaniju
            </Link>
            <Link
              to="/signup"
              className="block w-full py-3 px-6 border border-transparent text-sm font-medium rounded-md homepage-link"
            >
              <UserAddOutlined style={{ fontSize: '20px', marginRight: '10px'}} /> Kreirajte usera
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeMessage;
