  import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
  import { useClient } from "../utils/client";
  import { useAuthContext } from "./useAuthContext";
 
  const GUEST_KEY = "guest";


  const useCreateGuest = () => {
    const { user } = useAuthContext();
    const client = useClient();
    const queryClient = useQueryClient();
    
    const { mutateAsync: createGuest, ...rest } = useMutation({
      mutationFn: (guest) => {
        return client("guest/createGuest", {
          method: "POST",
          body: guest,
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(GUEST_KEY);
      },
    });
  
    return { ...rest, createGuest };
  };

  const useGetGuest = (guestId) => {
      const client = useClient();
    
      const { data: guest, ...rest } = useQuery({
        queryKey: ["guest", guestId],
        queryFn: () => client(`apartment/${guestId}`),
        enabled: !!guestId, 
      });
    
      return { ...rest, guest };
    };

  const useGetAllGuests = () => {
    const client = useClient();
    const { data: guests, ...rest } = useQuery({
      queryKey: [GUEST_KEY],
      queryFn: () => client("guest/allGuests"),
    });

    return { ...rest, guests };
  };

  const useUpdateGuest = () => {
    const client = useClient();
    const queryClient = useQueryClient();
    const { mutateAsync: updateGuest, ...rest } = useMutation({
      mutationFn: (updatedGuest) =>
        client(`guest/updateGuest/${updatedGuest.id}`, {
          method: "PUT",
          body: updatedGuest,
        }),
      onSuccess: () => {
        queryClient.invalidateQueries(GUEST_KEY);
      },
    });
    return { ...rest, updateGuest };
  };

  const useDeleteGuest = () => {
    const { user } = useAuthContext();
    const queryClient = useQueryClient();
    const client = useClient();
    const { mutateAsync: deleteGuest, ...rest } = useMutation({
      mutationFn: (guestId) =>
        client(`guest/deleteGuest/${guestId}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${user.token}`,
          },
        }),
      onSuccess: () => {
        queryClient.invalidateQueries(GUEST_KEY);
      },
    });

    return { ...rest, deleteGuest };
  };
  export {
      useCreateGuest,
      useGetGuest,
      useGetAllGuests,
      useUpdateGuest,
      useDeleteGuest,
  };
