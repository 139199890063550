import React from "react";
import { Navigate } from "react-router-dom";
import Error404 from "./Error404";

const ProtectedRoute = ({ isAllowed, redirectPath = "/", children }) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Error404 />;
};

export default ProtectedRoute;
