import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, notification, Card, Typography, Spin, Descriptions, DatePicker, Pagination, Result, Drawer } from "antd";
import { useGetApartment } from "../hooks/apartment";
import ReservationForm from "../components/ReservationForm";
import BookingCalendar from "../components/BookingCalendar";
import { useCreateReservation, useGetReservationsByApartmentId, useUpdateReservation, useDeleteReservation, useCloseReservation, useGetReservationsByApartmentAndMonth } from "../hooks/reservation";
import { useAuthContext } from "../hooks/useAuthContext";
import { ScheduleFilled } from "@ant-design/icons";
import dayjs from 'dayjs';
import { EditOutlined, DeleteOutlined, ScheduleOutlined, TeamOutlined, UserOutlined, CheckSquareFilled, ShopOutlined  } from "@ant-design/icons";
const { Title, Paragraph } = Typography;


const ApartmentPage = () => {
  const { id } = useParams();
  const { user } = useAuthContext();
  const isManager = user?.role === 'manager'; 
  const { apartment, isLoading, error } = useGetApartment(id);
  const { reservations, isLoading: reservationsLoading, isError } = useGetReservationsByApartmentId(id);

  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const { reservationsMonth, isLoading: isLoadingMonth } = useGetReservationsByApartmentAndMonth(id, selectedMonth && selectedMonth.format('YYYY-MM'));

  const { createReservation } = useCreateReservation();
  const { updateReservation } = useUpdateReservation();
  const { deleteReservation } = useDeleteReservation();
  const { closeReservation } = useCloseReservation();
  const [isDrawerVisible, setisDrawerVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedReservation, setSelectedReservation] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isCloseModalVisible, setIsCloseModalVisible] = useState(false);
  const [formKey, setFormKey] = useState(Date.now());
  const [filteredReservations, setFilteredReservations] = useState();
  const [currentPage, setCurrentPage] = useState(1); 

  const pageSize = 10;

    useEffect(() => {
      if (!isLoadingMonth && reservationsMonth) {
        setFilteredReservations(reservationsMonth);
      }
    }, [reservationsMonth, isLoadingMonth]);
  
    const handleDatePickerChange = (date) => {
      setSelectedMonth(date);
    };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const showModal = (reservation) => {
    setSelectedReservation(reservation);
    setIsEditMode(true);
    setisDrawerVisible(true);
  };

  const showDeleteModal = (reservation) => {
    setSelectedReservation(reservation);
    setIsDeleteModalVisible(true);
  };

  const showCloseModal = (reservation) => {
    setSelectedReservation(reservation);
    setIsCloseModalVisible(true);
  };

  const handleCancel = () => {
    setisDrawerVisible(false);
    setIsEditMode(false);
    setSelectedReservation(null);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedReservation(null);
  };

  const handleCloseCancel = () => {
    setIsCloseModalVisible(false);
    setSelectedReservation(null);
  };

  const formatReservationData = (values) => {
    return {
      ...values,
      apartmentId: id,
      userId: user.userId,
      apartmentPercentage: apartment.percentage,
      apartmentName: apartment.name,
    };
  };

  const handleCreateReservation = async (values) => {
    const formattedValues = formatReservationData(values);
    try {
      await createReservation(formattedValues);
      setFormKey(Date.now());
      setisDrawerVisible(false);
      notification.success({
        message: 'Success',
        description: 'Reservation has been created successfully.',
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Reservation Failed',
        description: 'There was an error creating the reservation.',
      });
    }
  };

  const handleUpdateReservation = async (values) => {
    const formattedValues = formatReservationData({
      ...values,
      id: selectedReservation.id,
    });
    try {
      await updateReservation(formattedValues);
      setFormKey(Date.now());
      setisDrawerVisible(false);
      setIsEditMode(false);
      setSelectedReservation(null);
      notification.success({
        message: 'Success',
        description: 'Reservation has been updated successfully.',
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Update Failed',
        description: 'There was an error updating the reservation.',
      });
    }
  };

  const handleDeleteReservation = async () => {
    try {
      await deleteReservation(selectedReservation.id);
      setIsDeleteModalVisible(false);
      setSelectedReservation(null);
      notification.success({
        message: 'Success',
        description: 'Reservation has been deleted successfully.',
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Delete Failed',
        description: 'There was an error deleting the reservation.',
      });
    }
  };

  const handleCloseReservation = async () => {
    try {
      await closeReservation(selectedReservation.id);
      setIsCloseModalVisible(false);
      setSelectedReservation(null);
      notification.success({
        message: 'Success',
        description: 'Reservation has been closed successfully.',
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Close Failed',
        description: 'There was an error closing the reservation.',
      });
    }
  };


  const paginatedReservations = filteredReservations?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );


  if (isLoading) return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Spin size="small" />
    </div>
  );

  if (error) return <div>Error: {error.message}</div>;

  return (
    <div className="apartment-details">
      <Card
        title={ <Title level={3} className="flex items-center justify-center" style={{ color: "#3730a3", textAlign: "center", margin: '0' }}>  {apartment.name} </Title>}
        extra={<Button className="create-reservation-btn"type="primary" onClick={() => setisDrawerVisible(true)}><ScheduleFilled  style={{ fontSize: '20px' }}/> <span>Kreiraj Rezervaciju</span></Button>}
        style={{ margin: '0 auto'}}
        className="card"
      >
        <div className="calendar-section" style={{ margin: '20px 0' }}>
          <BookingCalendar reservations={reservations} />
        </div>
        <Descriptions bordered column={1}>
          <Descriptions.Item label="Adresa:">
            <Paragraph style={{ fontSize: '16px' }}>{apartment.address}</Paragraph>
          </Descriptions.Item>
          <Descriptions.Item label="Opis apartmana:">
            <Paragraph style={{ fontSize: '16px' }}>{apartment.desc}</Paragraph>
          </Descriptions.Item>
          <Descriptions.Item label="Maksimalan broj gostiju:">
            <Paragraph style={{ fontSize: '16px' }}>{apartment.guestNumber}</Paragraph>
          </Descriptions.Item>
          <Descriptions.Item label="Cena po nocenju:">
            <Paragraph style={{ fontSize: '16px' }}>{apartment.pricePerDay} din</Paragraph>
          </Descriptions.Item>
        </Descriptions>
      </Card>

      <div className="reservation-header">
            <Title level={4}>Reservations</Title>
            <DatePicker inputReadOnly onChange={handleDatePickerChange} picker="month"  />
      </div>
      <div className="reservations-list" style={{ marginTop: '20px' }}>
        {reservationsLoading ? (
          <Spin size="small" />
        ) : isError ? (
          <div>Error loading reservations</div>
        ) : (
          paginatedReservations && paginatedReservations?.length > 0 ? (
            paginatedReservations.map(reservation => (
              <Card
              key={reservation.id}
              style={{
                width: 300,
                borderRadius: "8px",
                boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
              }}
              actions={  !reservation.closed && [
                <Button
                  type="link"
                  icon={<EditOutlined />}
                  onClick={() => showModal(reservation)} 
                >
                  Edit
                </Button>,
                <Button
                  danger
                  type="link"
                  icon={<DeleteOutlined />}
                  onClick={() => showDeleteModal(reservation)}
                >
                  Delete
                </Button>,
              ]}
            >
              <Card.Meta
                title={
                  <div className="flex justify-between items-center">
                  <div className="text-lg text-center"><UserOutlined /> {reservation.guestName} </div>  
             {  !reservation.closed &&    <Button    
                    className="close-reservation-btn"
                    type="link"
                    onClick={() => showCloseModal(reservation)} 
                    icon={<CheckSquareFilled />}
                    >
                    Close
                  </Button>}
                  </div>
                }
                description={
                  <> 
                    <Paragraph className="text-indigo-600 text-lg">
                    <ShopOutlined style={{ fontSize: '20px' }} /> {reservation.apartmentName}
                    </Paragraph>
                    <Paragraph className="text-indigo-600 text-lg">
                     {dayjs(reservation.startDate).format('DD MMM YYYY')} - {dayjs(reservation.endDate).format('DD MMM YYYY')}
                    </Paragraph>
                    <Paragraph className="text-gray-900 text-base">
                      <ScheduleOutlined style={{ fontSize: '20px' }} /> {reservation.bookedType}
                    </Paragraph>
                    <Paragraph className="text-gray-900 text-base">
                      <TeamOutlined style={{ fontSize: '20px' }} /> {reservation.guestNumber}
                    </Paragraph>
                    <Paragraph className="text-gray-900 text-base">
                      Phone: {reservation.guestPhone}
                    </Paragraph>
                    <Paragraph className="text-gray-900 text-base">
                      Email: {reservation.guestEmail}
                    </Paragraph>
                 { !isManager && (  <>
                     <Paragraph className="font-medium text-gray-900 text-base">
                      Ukupna cena: {reservation.priceSum} din
                    </Paragraph>
                    <Paragraph className="font-medium text-gray-900 text-base">
                      Troskovi: {reservation.costs} din
                    </Paragraph>
                    <Paragraph className="font-medium text-gray-900 text-base">
                      Vlasnik zarada: {reservation.ownerEarnings} din
                    </Paragraph>
                    <Paragraph className="font-medium text-indigo-600 text-lg text-right">
                      Zarada: {reservation.myEarnings} din
                    </Paragraph>
                    </>
                  )}
                    </>
                }
              />
            </Card>
            ))
          ) : (
            <div className="mx-auto">
            <Result
              status="404"
              title="Nema reervacija za ovaj mesec."
              subTitle="Izvinite, pokusajte da odaberete druge mesec kako bi dobili rezervacije."
            />
          </div>
          )
        )}
      </div>
      {filteredReservations?.length > pageSize && (
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={filteredReservations.length}
          onChange={handlePageChange}
          className="pagination-content"
        />
      )}
    <Drawer width={640} placement="right" closable={true} onClose={handleCancel} open={isDrawerVisible}  title={`${isEditMode ? `Edituj rezervaciju za ${selectedReservation?.guestName}` : `Kreiraj rezervaciju za ${apartment.name}`}`} > 
      <ReservationForm
            onFinish={isEditMode ? handleUpdateReservation : handleCreateReservation}
            apartment={apartment}
            formKey={formKey}
            initialValues={isEditMode ? selectedReservation : {}}
          />
      </Drawer>

      <Modal
        style={{ top: 10 }}
        open={isDeleteModalVisible}
        title={`Izbriši rezervaciju`}
        onCancel={handleDeleteCancel}
        onOk={handleDeleteReservation}
        okText="Delete"
        cancelText="Cancel"
        width={400}
      >
         <Paragraph>Da li ste sigurni da želite da izbrišete rezervaciju za gosta {selectedReservation?.guestName} od {dayjs(selectedReservation?.startDate).format('DD MMM YYYY')} - {dayjs(selectedReservation?.endDate).format('DD MMM YYYY')}</Paragraph>
      </Modal>

      <Modal
        style={{ top: 10 }}
        open={isCloseModalVisible}
        title={`Zatvori rezervaciju`}
        onCancel={handleCloseCancel}
        onOk={handleCloseReservation}
        okText="Close"
        cancelText="Cancel"
        width={400}
      >
        <Paragraph>Da li ste sigurni da želite da zatvorite rezervaciju za gosta {selectedReservation?.guestName} od {dayjs(selectedReservation?.startDate).format('DD MMM YYYY')} - {dayjs(selectedReservation?.endDate).format('DD MMM YYYY')}?</Paragraph>
      </Modal>
    </div>
  );
};

export default ApartmentPage;
