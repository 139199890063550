import React from "react";
import { useCreateCompany } from "../hooks/company";
import { Link, useNavigate } from "react-router-dom"; 
import { Form, Input, Button, message } from "antd";
import { HomeOutlined } from '@ant-design/icons';

const HouseForm = () => {
  const navigate = useNavigate();
  const { error, isSuccess, createCompany } = useCreateCompany();
  const [form] = Form.useForm();

  const handleSubmit = async (values) => {
    try {
      await createCompany({
        name: values.name,
        address: values.address,
        email: values.email,
        phoneNumber: values.phoneNumber,
      });
      message.success("Kuća je uspešno kreirana!");
      form.resetFields();
    } catch (error) {
      console.error(error);
      message.error("Došlo je do greške prilikom kreiranja kuće.");
    }
  };

  return (
    <div className="flex items-start justify-center ">
      <Button
        type="default"
        onClick={() => navigate("/admin-card")}
        className="back-btn"
      >
        <HomeOutlined /> Nazad
      </Button>

      <div className="welcome-card bg-white p-6 rounded-lg mt-20" style={{ width: '400px', height: '600px', boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px", margin: "120px auto", border: '2px solid #473fcf' }}>
     
        {isSuccess ? (
          <div className="mt-40">
            <p className="text-black-500 text-center my-20">Uspešno ste kreirali kompaniju! Pređite na kreiranje korisnika.</p>
            <Link
              className="block w-full py-3 px-6 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-center"
              to="/signup"
            >
              Kreiraj korisnika
            </Link>
          </div>
        ) : (
          <>
          <h2 className="text-2xl font-extrabold text-gray-900 text-center mb-12">
          Dodaj novu kompaniju
        </h2>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            className="space-y-6"
          >
            <Form.Item
              label="Naziv kompanije"
              name="name"
              rules={[{ required: true, message: "Molimo unesite naziv kuće!" }]}
            >
              <Input placeholder="Naziv kuće" />
            </Form.Item>
            <Form.Item
              label="Adresa"
              name="address"
            >
              <Input placeholder="Unesite adresu" />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ type: 'email', message: "Unesite ispravan email!" }]}
            >
              <Input type="email" placeholder="Unesite email" />
            </Form.Item>
            <Form.Item
              label="Broj telefona"
              name="phoneNumber"
            >
              <Input type="tel" placeholder="Unesite broj telefona" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="w-full flex justify-center"
              >
                Kreiraj kompaniju
              </Button>
            </Form.Item>
            {error && <div className="text-sm text-red-600">{error.message}</div>}
          </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default HouseForm;
