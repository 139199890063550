import React, { useState } from 'react';
import { UserOutlined,ScheduleFilled, PhoneFilled,CarryOutFilled, ClockCircleFilled  } from '@ant-design/icons';
import {  Calendar, Divider, Drawer } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useAuthContext } from '../hooks/useAuthContext';
dayjs.extend(utc);

const getOccupiedDates = (reservations) => {
  const occupiedDates = {};

  if (!reservations || reservations.length === 0) {
    return occupiedDates;
  }

  reservations.forEach(({ startDate, endDate, ...rest }) => {
    const start = dayjs.utc(startDate);
    const end = dayjs.utc(endDate);

    let currentDate = start;
    while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {  // Uključi end date
      const dateKey = currentDate.format('YYYY-MM-DD');
      occupiedDates[dateKey] = (occupiedDates[dateKey] || []).concat({
        type: 'error',
        content: {
          ...rest,
          startDate,
          endDate,
        },
      });
      currentDate = currentDate.add(1, 'day'); // Povećaj datum za jedan dan
    }
  });

  return occupiedDates;
};

const BookingCalendar = ({ reservations = [] }) => {
  const { user } = useAuthContext();
  const isManager = user?.role === 'manager';
  const [open, setOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState([]);

  const showDrawer = (content) => {
    setDrawerContent(content);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const occupiedDates = getOccupiedDates(reservations);

  const getListData = (value) => {
    const dateKey = `${value.year()}-${(value.month() + 1).toString().padStart(2, '0')}-${value.date().toString().padStart(2, '0')}`;
    return occupiedDates[dateKey] || [];
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    const hasEvents = listData.length > 0;

    const handleClick = () => {
      if (hasEvents) {
        setDrawerContent(listData.map(item => item.content));
        showDrawer(listData.map(item => item.content));
      }
    };

    const cellClassName = hasEvents
      ? listData.some(item => item.content.bookedType === 'booking')
        ? 'booking'
        : 'phone'
      : '';

    return (
      <div
        className={`event-cell ${cellClassName}`}
        onClick={handleClick}
        style={{ cursor: hasEvents ? 'pointer' : 'default' }}
      >
        {hasEvents && listData.map((item, index) => (
          <div key={index} className="event-info">
            <div className='booked-img'>
              {item.content.bookedType === 'booking' ? <ScheduleFilled style={{ fontSize: '28px', color: '#483fd1' }} /> : <PhoneFilled style={{ fontSize: '28px', color: '#009688' }} />}
            </div>
            <div className='calendar-day'>
              <div className='calendar-day-info'>{item.content.guestName}</div>
              <div className='calendar-day-info'>{item.content.guestPhone}</div>
              <div className={`calendar-day-info ${item.content.bookedType === 'booking' ? 'price' : 'phone'}`}>{item.content.pricePerDay} din</div>
              <div className='calendar-day-info'>{item.content.guestNumber} <UserOutlined /></div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const cellRender = (current, info) => {
    if (info.type === 'date') return dateCellRender(current);
    return info.originNode;
  };

  return (
    <>
      <Calendar cellRender={cellRender} />
      <Drawer width={640} placement="right" closable={true} onClose={onClose} open={open} title="Detalji rezervacije" >
        {drawerContent.map((content, index) => (
          <div key={index} className="drawer-info">
              <div className='reservation-info status'>Status: <span> {content.closed ? <CarryOutFilled style={{ fontSize: '28px', color: '#4CAF50' }}/>  : <ClockCircleFilled style={{ fontSize: '28px', color: '#FFC107' }}/> }</span></div>
              <div className='reservation-info'>Ime gosta: <span> {content.guestName}</span></div>
              <div className='reservation-info'>Broj telefona:<span> {content.guestPhone}</span></div>
              <div className='reservation-info'>Email:<span> {content.guestEmail}</span></div>
              <div className='reservation-info'>Od:<span>{dayjs.utc(content.startDate).format('DD-MM-YYYY')}</span></div>
              <div className='reservation-info'>Do:<span> {dayjs.utc(content.endDate).format('DD-MM-YYYY')}</span></div>
              <div className='reservation-info'>Cena po nocenju:<span> {content.pricePerDay} din</span></div>
              <div className='reservation-info'>Ukupna cena:<span> {content.priceSum} din</span></div>
     {!isManager && (      <>
        <div className='reservation-info'>Troskovi:<span> {content.costs} din</span></div>
             {content.apartmentPercentage && <div className='reservation-info'>Procenat apartmana:<span> {content.apartmentPercentage} %</span></div>}
             {content.apartmentPercentage && <div className='reservation-info'>Zarada vlasnika apartmana:<span> {content.ownerEarnings} din</span></div>}
              <div className='reservation-info earnings'>Zarada:<span> {content.myEarnings} din</span></div>
              </>
              )}
            <Divider />
          </div>
        ))}
      </Drawer>
    </>
  );
};


export default BookingCalendar;
