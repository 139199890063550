import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useAvailableApartments } from "../hooks/apartment";
import { useGetAllApartmentCategories } from "../hooks/apartmentCategory";
import { useAuthContext } from "../hooks/useAuthContext";
import { Link } from "react-router-dom";
import { Card, Pagination, Typography, DatePicker, Result, Button, Modal, Radio } from "antd";
import { ShopOutlined, TeamOutlined, FireFilled } from '@ant-design/icons';

const { Title, Paragraph } = Typography;
const { RangePicker } = DatePicker;

const AvailableApartments = () => {
  const { user } = useAuthContext();
  const [range, setRange] = useState([]);
  const [availableApartments, setAvailableApartments] = useState([]);
  const { apartmentCategories = [] } = useGetAllApartmentCategories();
  const [current, setCurrent] = useState(1);
  const [dataFetched, setDataFetched] = useState(false);
  const [language, setLanguage] = useState('sr');
  const [showLanguageModal, setShowLanguageModal] = useState(false);
  const pageSize = 10;

  const isManagerSumadija = user?.role === 'managerSumadija';
  const sumadijaCategory = apartmentCategories?.find((item) => item.name === "Sumadija");
  const sumadijaCategoryId = sumadijaCategory?.id;
  const sumadijaApartments = availableApartments?.filter((item) => Number(item.apartmentCategoryId) === sumadijaCategoryId);

  const { availableApartments: fetchedAvailableApartments } = useAvailableApartments(
    range[0]?.format('YYYY-MM-DD') || '',
    range[1]?.format('YYYY-MM-DD') || ''
  );

  useEffect(() => {
    if (fetchedAvailableApartments !== undefined) {
      setAvailableApartments(fetchedAvailableApartments);
      setDataFetched(true);
    }
  }, [fetchedAvailableApartments]);

  const handleRangeChange = (dates) => {
    if (dates) {
      const startDateStr = dates[0].startOf('day').format('YYYY-MM-DD');
      const endDateStr = dates[1].endOf('day').format('YYYY-MM-DD');
      setRange([dayjs(startDateStr), dayjs(endDateStr)]);
    } else {
      setRange([]);
    }
  };

  const handlePageChange = (page) => {
    setCurrent(page);
  };

  const paginatedApartments = isManagerSumadija
  ? sumadijaApartments?.slice((current - 1) * pageSize, current * pageSize)
  : availableApartments.slice(
    (current - 1) * pageSize,
    current * pageSize
  );


  const generateCopyText = (lang) => {
    if (!range[0] || !range[1]) return '';

    const startDateStr = range[0].format('YYYY-MM-DD');
    const endDateStr = range[1].format('YYYY-MM-DD');

    const numberOfDays = range[1].diff(range[0], 'day') + 1;
    const list = isManagerSumadija ?sumadijaApartments : availableApartments;
    const apartmentsText =  list.map(apartment => {
      const totalPrice = apartment.pricePerDay * numberOfDays;
      if (lang === 'en') {
        return `"${apartment.name}"
  Address: ${apartment.address}
  Price per night: ${apartment.pricePerDay} din
  Total price for period (${numberOfDays} days): ${totalPrice} din`;
      } else {
        return `"${apartment.name}"
  Adresa: ${apartment.address}
  Cena po nocenju: ${apartment.pricePerDay} din
  Ukupna cena za period (${numberOfDays} dana): ${totalPrice} din`;
      }
    }).join('\n\n');

    if (lang === 'en') {
      return `Dear Sir/Madam, for the dates from ${startDateStr} to ${endDateStr},\navailable apartments are:\n\n${apartmentsText}\n\nThank you for your inquiry. If any of the apartments interest you, feel free to call or send a message! Best regards.\n\nCheck the apartments: https://www.apartmanicentralvb.rs/apartmani/`;
    } else {
      return `Poštovani, za datum od ${startDateStr} do ${endDateStr},\nslobodni su apartmani:\n\n${apartmentsText}\n\nHvala vam na pozivu, ukoliko se odlučite za neki od apartmana, slobodno pozovite ili pošaljite poruku! Svako dobro.\n\nPogledajte apartmane na linku: https://www.apartmanicentralvb.rs/apartmani/`;
    }
  };

  const copyToClipboard = () => {
    setShowLanguageModal(true);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleConfirmCopy = () => {
    const textToCopy = generateCopyText(language);
    navigator.clipboard.writeText(textToCopy);
    setShowLanguageModal(false); // Close modal after copying
  };

  return (
    <div className="mx-auto">
      <Title level={3} className="flex items-center justify-center" style={{ color: "#3730a3", textAlign: "center", margin: '5px 0 15px 0' }}>
        <ShopOutlined style={{ fontSize: '20px', marginRight: '8px' }} /> Slobodni apartmani
      </Title>
    <div className="avaiable-apartments-rp">
      <RangePicker
        format="YYYY-MM-DD"
        inputReadOnly
        className="avaiable-apartments-rp"
        onChange={handleRangeChange}
        style={{ marginBottom: 16, width: '100%' }}
        value={range[0] && range[1] ? [range[0], range[1]] : []}
      />
    </div>

      <div className="apartments-content flex flex-wrap gap-4 mt-5 mb-10">
        {paginatedApartments.length > 0 && (
          paginatedApartments.map((apartment) => (
            <Card
              key={apartment.id}
              style={{
                width: 300,
                borderRadius: "8px",
                boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
              }}
            >
              <Card.Meta
                title={
                  <Link
                    to={`/apartment/${apartment.id}`}
                    className="apartment-name text-lg font-semibold text-gray-900"
                  >
                    <ShopOutlined /> {apartment.name}
                  </Link>
                }
                description={
                  <Link
                    to={`/apartment/${apartment.id}`}
                    className="text-lg font-semibold text-gray-900"
                  >
                    <Paragraph className="text-gray-900">Ul: {apartment.address}</Paragraph>
                    <Paragraph className="text-gray-900">
                      <TeamOutlined style={{ fontSize: '20px' }} /> {apartment.guestNumber}
                    </Paragraph>
                    <Paragraph className="text-gray-900">
                      Tip: {apartment.type}
                    </Paragraph>
                    <Paragraph className="text-base font-medium text-indigo-600">
                      {apartment.pricePerDay} din
                    </Paragraph>
                  </Link>
                }
              />
            </Card>
          ))
        )}
      </div>

      {availableApartments.length > 0 && (
        <div className="text-center">
          <Button
            className="copy-btn"
            icon={<FireFilled />}
            onClick={copyToClipboard}
            style={{ marginBottom: 40 }}
          >
            Kopiraj!
          </Button>
        </div>
      )}

      {!dataFetched ? null : (
        availableApartments.length === 0 && (
          <div className="mx-auto">
            <Result
              status="404"
              title="Nema slobodnih apartmana."
              subTitle="Izvinite, pokusajte da odaberete druge datume kako bi dobili slobodne apartmane."
            />
          </div>
        )
      )}

      {paginatedApartments.length > pageSize && (
        <Pagination
          current={current}
          pageSize={pageSize}
          total={availableApartments.length}
          onChange={handlePageChange}
          className="pagination-content"
        />
      )}

      <Modal
        style={{
          top: 10,
        }}
        title="Izaberi jezik"
        open={showLanguageModal}
        onCancel={() => setShowLanguageModal(false)}
        footer={[
          <Button key="back" onClick={() => setShowLanguageModal(false)}>
            Odustani
          </Button>,
          <Button key="submit" type="primary" onClick={handleConfirmCopy}>
            Kopiraj
          </Button>,
        ]}
      >
        <Radio.Group onChange={handleLanguageChange} value={language}>
          <Radio value="sr">Srpski</Radio>
          <Radio value="en">English</Radio>
        </Radio.Group>
      </Modal>
    </div>
  );
};

export default AvailableApartments;
