import React, { useEffect } from "react";
import { Form, Input, Button, message, InputNumber } from "antd";
import { useCreateApartmentCategory } from "../hooks/apartmentCategory";

const ApartmentCategoryForm = ({ category, onClose, onUpdate, form }) => {
  const { createApartmentCategory, error, isSuccess } = useCreateApartmentCategory();

  useEffect(() => {
    if (category) {
      form.setFieldsValue(category);
    } else {
      form.resetFields();
    }
  }, [category, form]);

  const handleFinish = async (values) => {
    if (category) {
      // Ako postoji kategorija, pozovite onUpdate
      onUpdate(values);
    } else {
      // Ako ne postoji, kreirajte novu kategoriju
      try {
        await createApartmentCategory(values);
        message.success("Kategorija stana je uspešno kreirana!");
        form.resetFields();
        onClose(); // Zatvori drawer nakon uspešnog kreiranja
      } catch (error) {
        console.error(error);
        message.error("Došlo je do greške prilikom kreiranja kategorije stana.");
      }
    }
  };

  return (
    <div>
          {isSuccess && (
          <p className="text-green-500 text-center mb-4">{isSuccess}</p>
        )}
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFinish}
        className="space-y-6"
      >
        <Form.Item
          label="Ime kategorije:"
          name="name"
          rules={[{ required: true, message: 'Molimo unesite ime kategorije!' }]}
        >
          <Input placeholder="Ime Kategorije" />
        </Form.Item>
        <Form.Item
          label="Broj telefona:"
          name="phoneNumber"
          rules={[{ message: 'Molimo unesite broj telefona!' }]}
        >
          <Input placeholder="Broj Telefona" />
        </Form.Item>
        <Form.Item
          label="Email:"
          name="email"
          rules={[{ type: 'email', message: 'Molimo unesite važeću email adresu!' }]}
        >
          <Input placeholder="Email" />
        </Form.Item>
        <Form.Item
          label="Opis:"
          name="desc"
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label="Troškovi održavanja:"
          name="maintenanceCosts"
        >
          <InputNumber className="w-full" placeholder="Troškovi održavanja" />
        </Form.Item>
        <Form.Item>
        <Button
              type="primary"
              htmlType="submit"
              className="w-full flex justify-center"
            >
             {category ? "Ažuriraj" : "Kreiraj"}
            </Button>
          </Form.Item>
          {error && <div className="text-sm text-red-600">{error}</div>}
      </Form>
    </div>
  );
};

export default ApartmentCategoryForm;
