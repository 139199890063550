import React, { useState } from 'react';
import { useAuthContext } from "../hooks/useAuthContext";
import { useGetAllGuests, useUpdateGuest, useDeleteGuest } from '../hooks/guest';
import { Button, Drawer, Form, Input, Typography, Card, Spin, Alert, Pagination, Row, notification, DatePicker, Modal } from 'antd';
import { EditOutlined, DeleteOutlined, UserAddOutlined, ContactsFilled } from '@ant-design/icons';
import GuestForm from '../components/GuestForm';
import dayjs from 'dayjs';

const { Title, Paragraph } = Typography;

const GuestsPage = () => {
  const { user } = useAuthContext();
  const { guests, isLoading, isError, error } = useGetAllGuests();
  const { updateGuest } = useUpdateGuest();
  const { deleteGuest } = useDeleteGuest();
  const [open, setOpen] = useState(false);
  const [editingGuest, setEditingGuest] = useState(null);
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const [visible, setVisible] = useState(false); // stanje za Drawer
  const isAdmin = user?.role === 'admin';
  const handleUpdate = async (values) => {
    try {
      const formattedValues = {
        ...editingGuest,
        ...values,
        birthOfDate: values.birthOfDate
          ? dayjs(values.birthOfDate).format("YYYY-MM-DD")
          : null,
      };

      await updateGuest(formattedValues);
      setEditingGuest(null);
      form.resetFields();
      setVisible(false); // zatvorimo Drawer
      notification.success({
        message: 'Uspešno!',
        description: 'Gost je uspešno ažuriran.',
      });
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Ažuriranje neuspešno!',
        description: 'Došlo je do greške prilikom ažuriranja gosta.',
      });
    }
  };

  const handleEditClick = (guest) => {
    setEditingGuest(guest);
    form.setFieldsValue({
      name: guest.name,
      email: guest.email,
      phoneNumber: guest.phoneNumber,
      jmbg: guest.jmbg,
      desc: guest.desc,
      birthOfDate: guest.birthOfDate ? dayjs(guest.birthOfDate) : null,
    });
    setVisible(true); // otvorimo Drawer
  };

  const handleDeleteClick = (guestId) => {
    Modal.confirm({
      title: 'Da li ste sigurni da želite da izbrišete ovog gosta?',
      content: 'Ova akcija se ne može poništiti.',
      okText: 'Da, izbriši',
      okType: 'danger',
      cancelText: 'Odustani',
      onOk: async () => {
        try {
          await deleteGuest(guestId);
          notification.success({
            message: 'Uspešno!',
            description: 'Gost je uspešno izbrisan.',
          });
        } catch (error) {
          console.error(error);
          notification.error({
            message: 'Brisanje neuspešno!',
            description: 'Došlo je do greške prilikom brisanja gosta.',
          });
        }
      },
    });
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleGuestAdded = () => {
    onClose()
  };

  if (isError) return <Alert message={`Greška: ${error.message}`} type="error" showIcon />;

  const paginatedGuests = guests?.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  const totalGuests = guests?.length;

  return (
    <div className="container container-wrap mx-auto">
      <Title level={3} className="flex items-center justify-between" style={{ color: "#3730a3", textAlign: "center", margin: '5px 0 15px 0' }}>
        <div></div>
        <div> <ContactsFilled style={{ fontSize: '25px', marginRight: '5px' }} /> Gosti </div>
      <div> {isAdmin &&   <Button className="create-reservation-btn"type="primary" onClick={() => showDrawer(true)}><UserAddOutlined  style={{ fontSize: '20px' }}/> <span>Kreiraj gosta</span></Button>}</div> 
      </Title>
      {isLoading ? (
        <Spin size="small" />
      ) : (
        <Row gutter={16}>
          {paginatedGuests.map((guest) => (
            <Card
              key={guest.id}
              style={{
                width: 300,
                borderRadius: "8px",
                boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
                margin: '10px',
              }}
             >
              <Card.Meta
                title={
                  <div className="text-lg font-semibold text-gray-900 mx-auto">
                    {guest.name}
                    <EditOutlined className='edit-icon'   onClick={() => handleEditClick(guest)} />
                    <DeleteOutlined className='delete-icon' onClick={() => handleDeleteClick(guest.id)} />
                  </div>
                }
                description={
                  <div className="mt-2">
                    <Paragraph className="text-gray-900">Email: {guest.email}</Paragraph>
                    <Paragraph className="text-gray-900">Broj telefona: {guest.phoneNumber}</Paragraph>
                    <Paragraph className="text-gray-900">JMBG: {guest.jmbg}</Paragraph>
                    <Paragraph className="text-gray-900">Opis: {guest.desc}</Paragraph>
                    <Paragraph className="text-gray-900">
                      Datum rođenja: {guest.birthOfDate ? dayjs(guest.birthOfDate).format('DD.MM.YYYY') : 'N/A'}
                    </Paragraph>
                  </div>
                }
              />
            </Card>
          ))}
                <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={totalGuests}
        onChange={(page) => setCurrentPage(page)}
        className="w-full pagination-content"
      />
        </Row>
      )}


         <Drawer width={640} placement="right" closable={true} onClose={onClose} open={open} title="Kreiraj gosta" >
            <GuestForm  onGuestAdded={handleGuestAdded} />
        </Drawer>

      <Drawer
        title="Edituj Gosta"
        width={720}
        onClose={() => setVisible(false)}
        open={visible}
        styles={{ paddingBottom: 80 }}
      >
        <Form form={form} onFinish={handleUpdate} layout="vertical">
          <Form.Item
            name="name"
            label="Ime:"
            rules={[{ required: true, message: 'Molimo unesite ime gosta!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="email" label="Email:">
            <Input />
          </Form.Item>
          <Form.Item
            name="phoneNumber"
            label="Broj telefona:"
            rules={[{ required: true, message: 'Molimo unesite broj telefona gosta!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="jmbg"
            label="JMBG:"
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="birthOfDate"
            label="Datum rođenja:"
          >
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>
          <Form.Item name="desc" label="Opis:">
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
              Sačuvaj
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
};

export default GuestsPage;
