import React, { useState } from "react";
import { useAuthContext } from "../hooks/useAuthContext";
import { useGetAllApartments, useDeleteApartment } from "../hooks/apartment";
import { useGetAllApartmentCategories } from "../hooks/apartmentCategory";
import { Link } from "react-router-dom";
import { Button, Card, Modal, Pagination, Typography, Tabs, Drawer } from "antd";
import {
  DeleteOutlined,
  ShopOutlined,
  HomeOutlined,
  TeamOutlined,
  EditOutlined,
} from '@ant-design/icons';
import ApartmentForm from "../components/ApartmentForm";
const { Title, Paragraph } = Typography;

const ApartmentsPage = () => {
  const { user } = useAuthContext();
  const { apartments = [] } = useGetAllApartments(user); 
  const { apartmentCategories = [] } = useGetAllApartmentCategories();
  const { deleteApartment } = useDeleteApartment();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [apartmentToDelete, setApartmentToDelete] = useState(null);
  const [current, setCurrent] = useState(1);
  const pageSize = 10;

  const isAdmin = user?.role === 'admin';
  const isManager = user?.role === 'manager';
  const isManagerSumadija = user?.role === 'managerSumadija';

  // Nađi kategorije Sumadija i Sumadija 2
  const sumadijaCategory = apartmentCategories?.find((item) => item.name === "Sumadija");
  const sumadija2Category = apartmentCategories?.find((item) => item.name === "Sumadija 2");

  const sumadijaCategoryId = sumadijaCategory?.id;
  const sumadija2CategoryId = sumadija2Category?.id;

  const [activeTab, setActiveTab] = useState(isManagerSumadija ? sumadijaCategoryId : "All");

  // Ako je isManagerSumadija, prikazujemo samo Sumadija i Sumadija 2
  const categories = isManagerSumadija
    ? [
        { id: sumadijaCategoryId, name: "Sumadija" },
        { id: sumadija2CategoryId, name: "Sumadija 2" },
      ]
    : [
        { id: "All", name: "All" },
        ...(Array.isArray(apartmentCategories)
          ? apartmentCategories.map((category) => ({ id: category.id, name: category.name }))
          : []),
      ];

  // Funkcija za filtriranje apartmana po kategoriji
  const renderApartmentsByCategory = (categoryId) => {
    if (categoryId === "All") {
      return apartments;
    } else {
      return apartments.filter(
        (apartment) => Number(apartment.apartmentCategoryId) === Number(categoryId)
      );
    }
  };

  const handleEditButtonClick = (apartment) => {
    setSelectedApartment(apartment);
    setIsDrawerOpen(true);
  };

  const createApartmanDrawer = () => {
    setSelectedApartment(null);
    setIsDrawerOpen(true)
  }
  const handleDeleteApartment = () => {
    if (apartmentToDelete) {
      deleteApartment(apartmentToDelete.id);
      setDeleteConfirmOpen(false);
    }
  };

  const handlePageChange = (page) => {
    setCurrent(page);
  };

  // Paginate apartmane u zavisnosti od aktivnog taba i kategorije
  const paginatedApartments = renderApartmentsByCategory(activeTab)?.slice(
    (current - 1) * pageSize,
    current * pageSize
  );

  const items = categories?.map((category) => ({
    key: category.id,
    label: category.name,
    children: (
      <div className="apartments-content flex flex-wrap gap-4 mt-5 mb-10">
        {paginatedApartments.length > 0 ? (
          paginatedApartments.map((apartment) => (
            <Card
              key={apartment.id} 
              style={{
                width: 300,
                borderRadius: "8px",
                boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px",
              }}
            >
              <Card.Meta
                title={
                  <>
                  <Link
                    to={`/apartment/${apartment.id}`}
                    className="apartment-name text-lg font-semibold text-gray-900"
                  >
                   <ShopOutlined /> {apartment.name}
                  </Link>

           { (!isManager && !isManagerSumadija) &&  (   
        <>
         <EditOutlined className='edit-icon'   onClick={() => handleEditButtonClick(apartment)} />
                  <DeleteOutlined className='delete-icon'  onClick={() => {
                    setApartmentToDelete(apartment);
                    setDeleteConfirmOpen(true);
                  }}/> 
                  </>
                 ) }
                  </>
                }
                description={
                  <Link
                    to={`/apartment/${apartment.id}`}
                    className="text-lg font-semibold text-gray-900"
                  >
                    <Paragraph className="text-gray-900">Ul: {apartment.address}</Paragraph>
                    <Paragraph className="text-gray-900">
                      <TeamOutlined  style={{ fontSize: '20px' }}/> {apartment.guestNumber}
                    </Paragraph>
                    <Paragraph className="text-gray-900">
                      Tip: {apartment.type}
                    </Paragraph>
                    <Paragraph className="text-base font-medium text-indigo-600">
                     {apartment.pricePerDay} din
                    </Paragraph>
                  </Link>
                }
              />
            </Card>
          ))
        ) : (
          <div className="text-center w-full">
            <Paragraph>Nema apartmana u ovoj kategoriji.</Paragraph>
          </div>
        )}
      </div>
    ),
  }));

  return (
    <div className="mx-auto">
      <Title level={3} className="flex items-center justify-between" style={{ color: "#3730a3", textAlign: "center", margin: '5px 0 15px 0' }}>
        <div></div>
        <div> <HomeOutlined style={{ fontSize: '25px', marginRight: '5px' }} /> Apartmani </div>
      <div>{isAdmin &&   <Button className="create-reservation-btn"type="primary" onClick={createApartmanDrawer}><ShopOutlined  style={{ fontSize: '20px' }}/> <span>Kreiraj apartman</span></Button>} </div> 
      </Title>
      <Tabs
        defaultActiveKey={isManagerSumadija ? sumadijaCategoryId : "All"}
        type="card"
        items={items}
        onChange={(key) => {
          setActiveTab(key);
          setCurrent(1); 
        }}
      />

      <Pagination
        current={current}
        pageSize={pageSize}
        total={paginatedApartments?.length}
        onChange={handlePageChange}
        className="pagination-content"
      />
      {isDrawerOpen && (
        <Drawer
          width={640}
          placement="right"
          closable={true}
          onClose={() => setIsDrawerOpen(false)}
          open={isDrawerOpen}
          title={selectedApartment ? `Edituj apartman - ${selectedApartment.name}` : "Kreiraj apartman"}
        >
          <ApartmentForm
            apartment={selectedApartment} // Prosledi izabrani apartman ako postoji
            onClose={() => setIsDrawerOpen(false)} // Zatvori nakon kreiranja ili editovanja
          />
        </Drawer>
      )}
      <Modal
          style={{
            top: 10,
          }}
        title="Confirm Delete"
        open={deleteConfirmOpen}
        onOk={handleDeleteApartment}
        onCancel={() => setDeleteConfirmOpen(false)}
        okText="Yes"
        cancelText="No"
      >
        <p>Da li ste sigurni da želite da obrišete ovaj apartman?</p>
      </Modal>
    </div>
  );
};

export default ApartmentsPage;
