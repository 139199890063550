import { createContext, useEffect, useReducer } from "react";
import { extractFromToken } from "../hooks/login";
export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { user: action.payload };
    case "LOGOUT":
      return { user: null };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    user: null,
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      const userId = extractFromToken(user.token, "id");
      const role = extractFromToken(user.token, "role");
      const email = extractFromToken(user.token, "email");
      const houseName = extractFromToken(user.token, "houseName");
 
      dispatch({ type: "LOGIN", payload: { ...user, role, email, houseName, userId } });
    }
  }, []);

  console.log("AuthContext state: ", state,);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
