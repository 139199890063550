import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useClient } from "../utils/client";
import { useAuthContext } from "./useAuthContext";

const RESERVATION_KEY = "reservations";

const useCreateReservation = () => {
  const { user } = useAuthContext();
  const client = useClient();
  const queryClient = useQueryClient();
  
  const { mutateAsync: createReservation, ...rest } = useMutation({
    mutationFn: (reservation) => {
      return client("reservation/createReservation", {
        method: "POST",
        body: reservation,
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(RESERVATION_KEY);
    },
  });

  return { ...rest, createReservation };
};
const useGetReservation = (reservationId) => {
  const client = useClient();

  const { data: reservation, ...rest } = useQuery({
    queryKey: ["reservation", reservationId],
    queryFn: () => client(`reservation/${reservationId}`),
    enabled: !!reservationId, 
  });

  return { ...rest, reservation };
};


const useGetAllReservations = () => {
  const client = useClient();
  const { data: reservations, ...rest } = useQuery({
    queryKey: [RESERVATION_KEY],
    queryFn: () => client("reservation/allReservations"),
  });

  return { ...rest, reservations };
};


const useUpdateReservation = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { mutateAsync: updateReservation, ...rest } = useMutation({
    mutationFn: (updatedReservation) =>
      client(`reservation/updateReservation/${updatedReservation.id}`, {
        method: "PUT",
        body: updatedReservation,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(RESERVATION_KEY);
    },
  });
  return { ...rest, updateReservation };
};

const useDeleteReservation = () => {
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const client = useClient();
  const { mutateAsync: deleteReservation, ...rest } = useMutation({
    mutationFn: (reservationId) =>
      client(`reservation/deleteReservation/${reservationId}`, {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${user.token}`,
        },
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(RESERVATION_KEY);
    },
  });

  return { ...rest, deleteReservation };
};

const useGetReservationsByApartmentId = (apartmentId) => {
  const client = useClient();

  const { data: reservations, ...rest } = useQuery({
    queryKey: ["reservations", apartmentId],
    queryFn: () => client(`reservation/reservationsByApartment/${apartmentId}`),
    enabled: !!apartmentId, 
  });

  return { ...rest, reservations };
};

 const useCloseReservation = () => {
  const { user } = useAuthContext();
  const queryClient = useQueryClient();
  const client = useClient();
  const { mutateAsync: closeReservation, ...rest } = useMutation({
    mutationFn: (reservationId) =>
      client(`reservation/closeReservation/${reservationId}`, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${user.token}`,
        },
        body: JSON.stringify({ status: 'closed' }), // Assuming the API needs a status field
      }),
    onSuccess: () => {
      queryClient.invalidateQueries('reservations');
    },
  });

  return { ...rest, closeReservation };
};

const useGetReservationsByApartmentAndMonth = (apartmentId, month) => {
  const client = useClient();

  const { data: reservationsMonth, ...rest } = useQuery({
    queryKey: ["reservations", apartmentId, month], // Dodajte month u queryKey
    queryFn: () => client(`reservation/apartment/${apartmentId}/month?month=${month}`), // Poziv API-ja sa apartmentId i month
    enabled: !!apartmentId && !!month, // Uverite se da su oba parametra dostupna
  });

  return { ...rest, reservationsMonth };
};

export {
    useCreateReservation,
    useGetReservation,
    useGetAllReservations,
    useUpdateReservation,
    useDeleteReservation,
    useGetReservationsByApartmentId,
    useCloseReservation,
    useGetReservationsByApartmentAndMonth,
};
