import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useClient } from "../utils/client";

const COMPANY_KEY = "companies";

const useCreateCompany = () => {
  const client = useClient();
  const queryClient = useQueryClient();
  const { mutateAsync: createCompany, ...rest } = useMutation({
    mutationFn: (bill) =>
      client("/company", {
        method: "post",
        body: bill,
        withoutAutorization: true,
      }),
    onSuccess: () => {
      queryClient.invalidateQueries(COMPANY_KEY);
    },
  });
  return { ...rest, createCompany };
};

export  {useCreateCompany};
