import React from "react";
import { useSignup } from "../hooks/login";
import { useNavigate } from "react-router-dom";
import { Form, Input, Button, Select, message } from "antd";
import { HomeOutlined } from '@ant-design/icons';

const Signup = () => {
  const navigate = useNavigate();
  const { signup, isLoading, isSuccess } = useSignup();
  const [form] = Form.useForm(); // Koristimo useForm za upravljanje formom

  const handleSubmit = async (values) => {
    try {
      await signup(values);
      message.success("Uspešno ste kreirali nalog!");
      form.resetFields(); // Resetujemo polja forme nakon uspešnog kreiranja naloga
    } catch (error) {
      console.error(error);
      message.error("Došlo je do greške prilikom kreiranja naloga.");
    }
  };

  return (
    <div className="flex items-start justify-center mt-10">
      <Button
        type="default"
        onClick={() => navigate("/admin-card")}
        className="back-btn"
      >
        <HomeOutlined /> Nazad
      </Button>
      <div className="welcome-card space-y-8 bg-white p-6 rounded-lg" style={{ width: '400px', height: '650px', boxShadow: "rgb(38, 57, 77) 0px 20px 30px -10px", margin: "80px auto", border: '2px solid #473fcf' }}>
        {!isSuccess ? (
          <>
            <h2 className="mt-6 text-center text-2xl font-extrabold text-gray-900">
              Kreiraj korisnika
            </h2>
            <Form
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              className="space-y-6"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: "Molimo unesite email!" }]}
              >
                <Input type="email" placeholder="Email adresa" />
              </Form.Item>

              <Form.Item
                label="Šifra"
                name="password"
                rules={[{ required: true, message: "Molimo unesite šifru!" }]}
              >
                <Input.Password placeholder="Šifra" />
              </Form.Item>

              <Form.Item
                label="House ID"
                name="companyId"
                rules={[{ required: true, message: "Molimo unesite House ID!" }]}
              >
                <Input placeholder="House ID" />
              </Form.Item>

              <Form.Item
                label="Uloga"
                name="role"
                rules={[{ required: true, message: "Molimo izaberite ulogu!" }]}
              >
                <Select placeholder="Izaberite ulogu">
                  <Select.Option value="admin">Admin</Select.Option>
                  <Select.Option value="user">Korisnik</Select.Option>
                  <Select.Option value="manager">Menadžer</Select.Option>
                </Select>
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isLoading}
                >
                  Kreiraj korisnika
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <div className="mt-40">
            <label className="text-center mt-4">
              Uspešno ste kreirali usera! Kliknite na prijavu da se ulogujete.
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;
